exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-celebrate-privately-js": () => import("./../../../src/pages/Celebrate-Privately.js" /* webpackChunkName: "component---src-pages-celebrate-privately-js" */),
  "component---src-pages-corporate-events-js": () => import("./../../../src/pages/Corporate-Events.js" /* webpackChunkName: "component---src-pages-corporate-events-js" */),
  "component---src-pages-gallery-altinsaray-js": () => import("./../../../src/pages/Gallery-Altinsaray.js" /* webpackChunkName: "component---src-pages-gallery-altinsaray-js" */),
  "component---src-pages-gallery-gumussaray-js": () => import("./../../../src/pages/Gallery-Gumussaray.js" /* webpackChunkName: "component---src-pages-gallery-gumussaray-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marriage-js": () => import("./../../../src/pages/Marriage.js" /* webpackChunkName: "component---src-pages-marriage-js" */)
}

